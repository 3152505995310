import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './Login.actions';
import * as selectors from './Login.selectors';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import './Login.scss';

const Login = (props) => {
    /* State to props */
    const isLogging = useSelector(selectors.getIsLogging);
    const form = useSelector(selectors.getForm, stringifyEqualityCheck);
    const user = useSelector(selectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const login = useCallback((history) => dispatch(actions.login(history)), [dispatch]);
    const setField = useCallback((name, value) => dispatch(actions.setField(name, value)), [dispatch]);
    const clearTokens = useCallback(() => dispatch(actions.clearTokens()), [dispatch]);
    const setType = useCallback((value) => dispatch(actions.setType(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');
        
        clearTokens();

        if (props.location.pathname === '/login') {
            setType('frontend');
        } else {
            setType('backend');
        }

        return () => {
            resetState();
        };
    }, [clearTokens, resetState, setType, props.location]);

    return (
        <div className="login-container">
            <Helmet>
                <title>Login - Scorecard</title>
            </Helmet>
            <Favicon url="https://sc-scorecard.s3.amazonaws.com/favicon.ico" />
            <Form
                form={form}
                user={user}
                isLogging={isLogging}
                history={props.history}
                location={props.location}
                login={login}
                setField={setField}
            />
        </div>
    );
};

export default Login;
