import { combineReducers } from 'redux';
import * as dialog from './components/common/dialog';
import AdminLayoutReducer from './components/AdminLayout/AdminLayout.reducer';
import AdminUsersReducer from './components/AdminUsers/AdminUsers.reducer';
import DashboardReducer from './components/Dashboard/Dashboard.reducer';
import LoginReducer from './components/Login/Login.reducer';
import ScorecardReducer from './components/Scorecard/Scorecard.reducer';

const reducer = combineReducers({
    dialog: dialog.reducer,
    adminLayout: AdminLayoutReducer,
    adminUsers: AdminUsersReducer,
    dashboard: DashboardReducer,
    login: LoginReducer,
    scorecard: ScorecardReducer,
});

export default reducer;
