export const FETCH_DATA = 'DASHBOARD/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'DASHBOARD/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'DASHBOARD/FETCH_DATA_ERROR';
export const SET_YEAR = 'DASHBOARD/SET_YEAR';
export const SET_MONTH = 'DASHBOARD/SET_MONTH';
export const SET_SEARCHED_YEAR = 'DASHBOARD/SET_SEARCHED_YEAR';
export const SET_SEARCHED_MONTH = 'DASHBOARD/SET_SEARCHED_MONTH';
export const SET_ROW = 'DASHBOARD/SET_ROW';
export const SET_CHART_TYPE = 'DASHBOARD/SET_CHART_TYPE';
export const SET_IS_DEFAULT_CHART = 'DASHBOARD/SET_IS_DEFAULT_CHART';
export const SET_HISTORY = 'DASHBOARD/SET_HISTORY';
export const RESET_STATE = 'DASHBOARD/RESET_STATE';