import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from './AdminLayout.selectors';
import * as actions from './AdminLayout.actions';
import * as loginActions from './../Login/Login.actions';
import * as loginSelectors from './../Login/Login.selectors';
import Favicon from 'react-favicon';
import Header from './Header';
import Sidebar from './Sidebar';
import TermOfUseModal from './TermOfUseModal';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from './../common/dialog';
import './AdminLayout.scss';

const AdminLayout = (props) => {
    /* State to props */
    const showSidebarMenu = useSelector(selectors.getShowSidebarMenu);
    const scorecardSection = useSelector(selectors.getScorecardSection);
    const isAuthenticated = useSelector(loginSelectors.getIsAuthenticated);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setShowSidebarMenu = useCallback(() => dispatch(actions.setShowSidebarMenu()), [dispatch]);
    const setScorecardSection = useCallback((value) => dispatch(actions.setScorecardSection(value)), [dispatch]);
    const logout = useCallback((history) => dispatch(loginActions.logout(history)), [dispatch]);
    const showTermOfUseModal = useCallback(() => dispatch(dialog.actions.showDialog('term-of-use-modal')), [dispatch]);
    const hideTermOfUseModal = useCallback(() => dispatch(dialog.actions.hideDialog('term-of-use-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);

        return () => {
            setScorecardSection(1);
        };
    }, [setScorecardSection]);

    if (user == null) {
        logout(props.history);
        return null;
    }

    const logoutHandler = event => {
        event.preventDefault();
        logout(props.history);
    }

    return (
        <div className="admin-layout-container">
            <Favicon url="https://sc-scorecard.s3.amazonaws.com/favicon.ico" />
            <Helmet>
                <title>Scorecard Admin</title>
            </Helmet>
            <div className="content-area">
                <Header
                    user={user}
                    isAuthenticated={isAuthenticated}
                    showSidebarMenu={showSidebarMenu}
                    history={props.history}
                    logoutHandler={logoutHandler}
                    setShowSidebarMenu={setShowSidebarMenu}
                />
                <div className="layout-content">
                    <Sidebar
                        user={user}
                        scorecardSection={scorecardSection}
                        history={props.history}
                        setScorecardSection={setScorecardSection}
                        showTermOfUseModal={showTermOfUseModal}
                    />
                    <div className="content">
                        {props.children}
                    </div>
                </div>
            </div>
            <TermOfUseModal
                hideTermOfUseModal={hideTermOfUseModal}
            />
        </div>
    );
};

export default AdminLayout;
