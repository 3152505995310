import { createSelector } from 'reselect';

export const getComponentState = state => state.adminLayout;

export const getShowSidebarMenu = createSelector(
    getComponentState,
    (state) => state.showSidebarMenu
);

export const getScorecardSection = createSelector(
    getComponentState,
    (state) => state.scorecardSection
);