import React, { Fragment } from 'react';
import ScorecardLineChart from './ScorecardLineChart';
import ScorecardAreaChart from './ScorecardAreaChart';
import ScorecardPieChart from './ScorecardPieChart';
import ScorecardBarChart from './ScorecardBarChart';

const Chart = (props) => {
    if (props.fetchingData) {
        return (
            <div className="loading-data">
                <i className="fas fa-circle-notch fa-spin" /> Loading data...
            </div>
        );
    }

    const scorecardSection = parseInt(props.scorecardSection);

    const handleSetChartType = (index, event) => {
        props.setChartType(index, event.target.value);
    };

    const handleSetDefaultChart = (index, rowNumber, event) => {
        const value = event.target.checked ? 1 : 0;
        props.setDefaultChart(rowNumber, value);
        props.setIsDefaultChart(index, value);
    };

    const validateIndex = (section, rowNumber) => {
        if (section === 1 && rowNumber >= 8 && rowNumber <= 80) {
            return true;
        }
        if (section === 2 && rowNumber >= 82 && rowNumber <= 123) {    // Full-Service Verifications
            return true;
        }
        if (section === 3 && rowNumber >= 125 && rowNumber <= 138) {    // Move/Update Verifications
            return true;
        }
        if (section === 4 && rowNumber >= 140 && rowNumber <= 145) {    // Entry Point Validations - Info Only
            return true;
        }
        if (section === 5 && rowNumber >= 147 && rowNumber <= 195) {    // eDoc Nesting/Sortation Validations - Info Only
            return true;
        }
        if (section === 6 && rowNumber >= 197 && rowNumber <= 205) {    // Seamless Verifications
            return true;
        }
        if (section === 7 && rowNumber >= 207 && rowNumber <= 210) {    // Automated Verifications
            return true;
        }
        if (section === 8 && rowNumber >= 212 && rowNumber <= 225) {    // Sampling Verifications
            return true;
        }
        if (section === 9 && rowNumber >= 227 && rowNumber <= 235) {    // Nesting Sampling Validations - Info Only
            return true;
        }
        if (section === 10 && rowNumber >= 237) {    // Mail Irregularities
            return true;
        }

        return false;
    };

    const renderChart = (chart, index) => {
        let numberFormat = '0,0';

        if (chart.valueType === 'integer') {
            numberFormat = '0,0';
        } else if (chart.valueType === 'percentage') {
            numberFormat = '0.00[00]%';
        } else if (chart.valueType === 'float') {
            numberFormat = '0,0.[0000]';
        } else if (chart.valueType === 'money') {
            numberFormat = '$0,0.00';
        } else if (chart.valueType === 'string') {
            numberFormat = '0,0';
        }

        if (chart.chartType === 'line') {
            return (
                <ScorecardLineChart
                    chartTitle={chart.name}
                    data={chart.data}
                    numberFormat={numberFormat}
                    yAxisWidth={100}
                    chartType={chart.chartType}
                    threshold={chart.threshold}
                    isDefaultChart={chart.isDefaultChart}
                    setChartType={handleSetChartType.bind(this, index)}
                    setDefaultChart={handleSetDefaultChart.bind(this, index, chart.rowNumber)}
                    lines={[
                        { name: chart.name, dataKey: 'value', color: '#2563eb' },
                    ]}
                />
            );
        } else if (chart.chartType === 'area') {
            return (
                <ScorecardAreaChart
                    chartTitle={chart.name}
                    data={chart.data}
                    numberFormat={numberFormat}
                    yAxisWidth={100}
                    chartType={chart.chartType}
                    threshold={chart.threshold}
                    isDefaultChart={chart.isDefaultChart}
                    setChartType={handleSetChartType.bind(this, index)}
                    setDefaultChart={handleSetDefaultChart.bind(this, index, chart.rowNumber)}
                    areas={[
                        { name: chart.name, dataKey: 'value', color: '#2563eb' },
                    ]}
                />
            );
        } else if (chart.chartType === 'bar') {
            return (
                <ScorecardBarChart
                    chartTitle={chart.name}
                    data={chart.data}
                    numberFormat={numberFormat}
                    yAxisWidth={100}
                    chartType={chart.chartType}
                    threshold={chart.threshold}
                    isDefaultChart={chart.isDefaultChart}
                    setChartType={handleSetChartType.bind(this, index)}
                    setDefaultChart={handleSetDefaultChart.bind(this, index, chart.rowNumber)}
                    bars={[
                        { name: chart.name, dataKey: 'value', color: '#2563eb' },
                    ]}
                />
            );
        }

        return null;
    };

    const getChartsHtml = (section) => {
        const chartsHtml = [];

        if (props.data.charts) {
            for (let i = 0; i < props.data.charts.length; i++) {
                if (validateIndex(section, props.data.charts[i].rowNumber)) {
                    chartsHtml.push(renderChart(props.data.charts[i], i));
                }
            }
        }

        return chartsHtml;
    };

    const renderSections = () => {
        const sections = [];

        if (scorecardSection === 1) {   // Complete View
            const sectionCharts = getChartsHtml(1);
            sections.push(
                <Fragment>
                    <h1>General</h1>
                    <ScorecardPieChart
                        chartTitle="Full-Service Jobs Monthly Average"
                        data={props.data.fullServiceJobsAverageData ? props.data.fullServiceJobsAverageData.data : []}
                        setChartType={handleSetChartType}
                        numberFormat="0.00%"
                        colors={['#2563eb', '#16a34a']}
                    />
                    <ScorecardPieChart
                        chartTitle="Jobs Monthly Average"
                        data={props.data.mailTypeJobsAverageData ? props.data.mailTypeJobsAverageData.data : []}
                        setChartType={handleSetChartType}
                        numberFormat="0.00%"
                        colors={['#2563eb', '#f97316', '#16a34a']}
                    />
                    {sectionCharts}
                </Fragment>
            );
        }
        if (scorecardSection === 1 || scorecardSection === 2) {  // Full-Service Verifications
            const sectionCharts = getChartsHtml(2);
            if (sectionCharts.length > 0) {
                sections.push(
                    <Fragment>
                        <h1>Full-Service Verifications</h1>
                        {sectionCharts}
                    </Fragment>
                );
            }
        }
        if (scorecardSection === 1 || scorecardSection === 3) {  // Move/Update Verifications
            const sectionCharts = getChartsHtml(3);
            if (sectionCharts.length > 0) {
                sections.push(
                    <Fragment>
                        <h1>Move/Update Verifications</h1>
                        {sectionCharts}
                    </Fragment>
                );
            }
        }
        if (scorecardSection === 1 || scorecardSection === 4) {  // Entry Point Validations - Info Only
            const sectionCharts = getChartsHtml(4);
            if (sectionCharts.length > 0) {
                sections.push(
                    <Fragment>
                        <h1>Entry Point Validations - Info Only</h1>
                        {sectionCharts}
                    </Fragment>
                );
            }
        }
        if (scorecardSection === 1 || scorecardSection === 5) {  // eDoc Nesting/Sortation Validations - Info Only
            const sectionCharts = getChartsHtml(5);
            if (sectionCharts.length > 0) {
                sections.push(
                    <Fragment>
                        <h1>eDoc Nesting/Sortation Validations - Info Only</h1>
                        {sectionCharts}
                    </Fragment>
                );
            }
        }
        if (scorecardSection === 1 || scorecardSection === 6) {  // Seamless Verifications
            const sectionCharts = getChartsHtml(6);
            if (sectionCharts.length > 0) {
                sections.push(
                    <Fragment>
                        <h1>Seamless Verifications</h1>
                        {sectionCharts}
                    </Fragment>
                );
            }
        }
        if (scorecardSection === 1 || scorecardSection === 7) {  // Automated Verifications
            const sectionCharts = getChartsHtml(7);
            if (sectionCharts.length > 0) {
                sections.push(
                    <Fragment>
                        <h1>Automated Verifications</h1>
                        {sectionCharts}
                    </Fragment>
                );
            }
        }
        if (scorecardSection === 1 || scorecardSection === 8) {  // Sampling Verifications
            const sectionCharts = getChartsHtml(8);
            if (sectionCharts.length > 0) {
                sections.push(
                    <Fragment>
                        <h1>Sampling Verifications</h1>
                        {sectionCharts}
                    </Fragment>
                );
            }
        }
        if (scorecardSection === 1 || scorecardSection === 9) {  // Nesting Sampling Validations - Info Only
            const sectionCharts = getChartsHtml(9);
            if (sectionCharts.length > 0) {
                sections.push(
                    <Fragment>
                        <h1>Nesting Sampling Validations - Info Only</h1>
                        {sectionCharts}
                    </Fragment>
                );
            }
        }
        if (scorecardSection === 1 || scorecardSection === 10) {  // Mail Irregularities
            const sectionCharts = getChartsHtml(10);
            if (sectionCharts.length > 0) {
                sections.push(
                    <Fragment>
                        <h1>Mail Irregularities</h1>
                        {sectionCharts}
                    </Fragment>
                );
            }
        }

        return sections;
    };

    return (
        <div className="charts-container">
            {renderSections()}
        </div>
    );
}

export default Chart;
