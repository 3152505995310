import React from 'react';
import ScorecardLineChart from './../../Dashboard/Charts/ScorecardLineChart';
import ScorecardAreaChart from './../../Dashboard/Charts/ScorecardAreaChart';
import ScorecardBarChart from './../../Dashboard/Charts/ScorecardBarChart';
import { Dialog } from './../../common/dialog';

const ChartModal = React.memo((props) => {
    const handleHideChartModal = () => {
        props.setChart(null);
        props.hideChartModal();
    };

    const handleSetChartType = (event) => {
        props.setChartType(event.target.value);
    };

    const handleSetDefaultChart = (event) => {
        const value = event.target.checked ? 1 : 0;
        props.setDefaultChart(props.chart.rowNumber, value);
        props.setIsDefaultChart(props.chart.rowNumber, value);
    };

    let content = <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    if (!props.fetchingChart && props.chart != null) {
        let numberFormat = '0,0';

        if (props.chart.valueType === 'integer') {
            numberFormat = '0,0';
        } else if (props.chart.valueType === 'percentage') {
            numberFormat = '0.00[00]%';
        } else if (props.chart.valueType === 'float') {
            numberFormat = '0,0.[0000]';
        } else if (props.chart.valueType === 'money') {
            numberFormat = '$0,0.00';
        } else if (props.chart.valueType === 'string') {
            numberFormat = '0,0';
        }

        if (props.chartType === 'line') {
            content = (
                <ScorecardLineChart
                    chartTitle={props.chart.name}
                    data={props.chart.data}
                    numberFormat={numberFormat}
                    yAxisWidth={100}
                    chartType={props.chartType}
                    threshold={props.chart.threshold}
                    isDefaultChart={props.chart.isDefaultChart}
                    setChartType={handleSetChartType}
                    setDefaultChart={handleSetDefaultChart}
                    lines={[
                        { name: props.chart.name, dataKey: 'value', color: '#2563eb' },
                    ]}
                />
            );
        } else if (props.chartType === 'area') {
            content = (
                <ScorecardAreaChart
                    chartTitle={props.chart.name}
                    data={props.chart.data}
                    numberFormat={numberFormat}
                    yAxisWidth={100}
                    chartType={props.chartType}
                    threshold={props.chart.threshold}
                    isDefaultChart={props.chart.isDefaultChart}
                    setChartType={handleSetChartType}
                    setDefaultChart={handleSetDefaultChart}
                    areas={[
                        { name: props.chart.name, dataKey: 'value', color: '#2563eb' },
                    ]}
                />
            );
        } else if (props.chartType === 'bar') {
            content = (
                <ScorecardBarChart
                    chartTitle={props.chart.name}
                    data={props.chart.data}
                    numberFormat={numberFormat}
                    yAxisWidth={100}
                    chartType={props.chartType}
                    threshold={props.chart.threshold}
                    isDefaultChart={props.chart.isDefaultChart}
                    setChartType={handleSetChartType}
                    setDefaultChart={handleSetDefaultChart}
                    bars={[
                        { name: props.chart.name, dataKey: 'value', color: '#2563eb' },
                    ]}
                />
            );
        }
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={handleHideChartModal}>Close</button>,
    ];
    return (
        <Dialog
            name="chart-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="admin-chart-dialog"
        >
            <div className="admin-chart-modal-container">
                {content}
            </div>
        </Dialog >
    );
});

export default ChartModal;
