import Immutable from 'immutable';
import * as types from './Dashboard.types';

const initialState = {
    data: [],
    fetchingData: false,
    year: '',
    month: '',
    searchedYear: '',
    searchedMonth: '',
    row: '',
    history: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_DATA:
            return state.setIn(['fetchingData'], true).toJS();

        case types.FETCH_DATA_SUCCESS:
            return state.setIn(['fetchingData'], false)
                .setIn(['data'], action.payload)
                .toJS();

        case types.FETCH_DATA_ERROR:
            return state.setIn(['fetchingData'], false).toJS();

        case types.SET_YEAR:
            return state.setIn(['year'], action.payload).toJS();

        case types.SET_MONTH:
            return state.setIn(['month'], action.payload).toJS();

        case types.SET_SEARCHED_YEAR:
            return state.setIn(['searchedYear'], action.payload).toJS();

        case types.SET_SEARCHED_MONTH:
            return state.setIn(['searchedMonth'], action.payload).toJS();

        case types.SET_ROW:
            return state.setIn(['row'], action.payload).toJS();

        case types.SET_CHART_TYPE:
            return state.setIn(['data', 'charts', action.payload.index, 'chartType'], action.payload.value).toJS();

        case types.SET_IS_DEFAULT_CHART:
            return state.setIn(['data', 'charts', action.payload.index, 'isDefaultChart'], action.payload.value).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}