import React from 'react';
import { Dialog } from './../../common/dialog';

const TermOfUseModal = React.memo((props) => {
    const hideHandler = () => {
        props.hideTermOfUseModal();
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Close</button>,
    ];
    return (
        <Dialog
            name="term-of-use-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 1000 }}
            bodyStyle={{ overflow: 'initial' }}
            className="term-of-use-dialog"
        >
            <div className="term-of-use-modal-container">
                <h1>SOFTWARE TERMS OF USE AGREEMENT</h1>
                <p>
                    Effective Date: December 13, 2017
                </p>
                <p>
                    PLEASE READ THIS SOFTWARE TERMS OF USE AGREEMENT ("Agreement") CAREFULLY BEFORE ACCESSING OR USING THE SOFTWARE. BY 
                    ACCESSING OR USING THE SOFTWARE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE TERMS 
                    AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, DO NOT ACCESS, OR USE THE SOFTWARE.
                </p>
                <p>
                    <strong>1. DEFINITIONS</strong>
                </p>
                <p>
                    Upon accessing or using the Software, you ("User") represent that you have read, understood, and agree to be bound by 
                    this Agreement in its entirety. This Agreement is a binding legal contract between you and the Developers of the Software 
                    ("Developers"). Should you not agree to any term or condition set forth herein, you are prohibited from using the Software 
                    and must discontinue its use immediately.
                </p>
                <p>
                    <strong>2. LICENSE GRANT AND USE OF THE SOFTWARE</strong>
                </p>
                <p>
                    Subject to the User's strict compliance with this Agreement, the Developers hereby grant the User a non-exclusive, non-transferable, 
                    revocable license to access and use the Software for its intended purposes. The User expressly agrees not to reverse engineer, 
                    decompile, disassemble, modify, rent, lease, loan, distribute, or create derivative works or improvements from the Software or 
                    any portion thereof. Any such unauthorized use shall result in immediate and automatic revocation of this license. This Agreement 
                    supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, between the parties.
                </p>
                <p>
                    <strong>3. USE OF SOFTWARE</strong>
                </p>
                <p>
                    Users are strictly prohibited from using the Software for any unlawful purpose or in a manner that could harm the Software or any 
                    third party. Users shall use the Software in compliance with all applicable laws, regulations, and this Agreement. Users shall not, 
                    and shall not permit any third party to: a) modify, adapt, translate, or create derivative works based on the Software; b) reverse 
                    engineer, decompile, or disassemble the Software; c) distribute, sublicense, lease, lend, or rent the Software; d) remove, alter, or 
                    obscure any proprietary notices or labels on the Software; e) use the Software to engage in any illegal, fraudulent, or unauthorized 
                    activities; f) interfere with or disrupt the integrity or performance of the Software or its related systems; g) use the Software to 
                    transmit or store any infringing, obscene, or offensive content; h) use the Software in a manner that may harm the reputation of 
                    Provider or its affiliates. User agrees to use the Software responsibly, in compliance with applicable laws and regulations, and not to 
                    infringe upon the rights of others.
                </p>
                <p>
                    <strong>4. INTELLECTUAL PROPERTY RIGHTS</strong>
                </p>
                <p>
                    4.1 Users acknowledge that the Provider or its licensors retain all rights, title, and interest in and to the Software, including all 
                    intellectual property rights.
                </p>
                <p>
                    4.2 Developers maintain comprehensive rights, title, and ownership over the Software Assets. Any rights not overtly provided under this 
                    Agreement are implicitly reserved by the Developers.
                </p>
                <p>
                    4.3 Users agree not to remove, alter, or obscure any proprietary rights notices on the Software or its related documentation.
                </p>
                <p>
                    <strong>5. USER'S PROPERTY RIGHTS</strong>
                </p>
                <p>
                    Every piece of data or information introduced into the Software by the User, all digital artwork generated as the result of the User's 
                    engagement with the Software, and any additional content crafted by the User which is housed within the Software database or any other 
                    portion of the Software—collectively referred to as "User Assets"—rests solely within the domain of User ownership. This encapsulates, 
                    but is not limited to, data entries, files uploaded, personalized settings, user profiles, digital designs created, and any form of User 
                    contribution. Also included are proprietary or personally identifiable information submitted by the User, encompassing but not limited 
                    to account information, user preferences, and individualized user patterns and habits. Regardless of their generation method or their 
                    location within the Software's ecosystem, these User Assets are the exclusive property of the User, who thus retains all corresponding 
                    rights, title, and interest over these User Assets.
                </p>
                <p>
                    <strong>6. DATA PRIVACY</strong>
                </p>
                <p>
                    The Developers will employ commercially reasonable and industry-standard measures to ensure the protection, security, and integrity of 
                    User Assets and the User's interaction with the Software. This includes but is not limited to, measures against unauthorized access, use, 
                    alteration, or disclosure of User Assets. Users grant Provider the right to use anonymized and aggregated data derived from their use of 
                    the Software for analytical and statistical purposes. Users acknowledge and agree that Provider may collect, store, and process their 
                    personal data and the personal data of their clients in accordance with the Privacy Policy. For more detailed information about the Developers' 
                    data protection and privacy practices, Users are advised to consult the Developers' Privacy Policy.
                </p>
                <p>
                    <strong>7. DISCLAIMER OF WARRANTIES</strong>
                </p>
                <p>
                    The Software and all its components are provided "AS IS", without any warranties or representations of any kind, whether express or 
                    implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. The 
                    Developers make no warranty that the Software will meet the User's requirements or that its operation will be uninterrupted or error-free.
                </p>
                <p>
                    <strong>8. LIMITATION OF LIABILITY</strong>
                </p>
                <p>
                    8.1 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, PROVIDER SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, 
                    CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS, DATA, OR BUSINESS INTERRUPTION, ARISING 
                    OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE SOFTWARE.
                </p>
                <p>
                    In no event shall the Developers or their affiliates, officers, directors, employees, or agents be liable to the User or any 
                    third party for any indirect, incidental, special, punitive, or consequential damages, or damages for lost profits, loss of 
                    revenue, loss of data, or loss of use, whether in contract, tort, or otherwise, arising out of or in any way connected with 
                    the use of, or inability to use, the Software.
                </p>
                <p>
                    <strong>9. TERM AND TERMINATION:</strong>
                </p>
                <p>
                    This Agreement commences upon the User's first use of the Software and will automatically renew each year on the anniversary 
                    of the User's first use unless the User gives the Developers at least thirty (30) days' notice before the end of the then-current 
                    term of their decision not to renew. The Developers can terminate this Agreement and the User's access to the Software at any 
                    time without notice if the User fails to comply with any of the terms of this Agreement. Upon termination, Users shall immediately 
                    cease all use of the Software and destroy any copies in their possession.
                </p>
                <p>
                    <strong>10. GOVERNING LAW AND DISPUTE RESOLUTION</strong>
                </p>
                <p>
                    This Agreement and any disputes arising out of or relating to this Agreement, or the use of the Software will be governed by 
                    the laws of the jurisdiction specified by the Developers, without regard to its conflicts of laws principles.
                </p>
                <p>
                    <strong>11. MISCELLANEOUS</strong>
                </p>
                <p>
                    This Agreement constitutes the entire agreement between Provider and Users relating to the Software and supersedes all prior 
                    or contemporaneous oral or written agreements, proposals, or representations. No failure or delay by either party in exercising 
                    any right, power, or remedy under this Agreement shall operate as a waiver.
                </p>
            </div>
        </Dialog>
    );
});

export default TermOfUseModal;
