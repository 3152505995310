import { createSelector } from 'reselect';

export const getComponentState = state => state.scorecard;

export const getData = createSelector(
    getComponentState,
    (state) => state.data
);
export const getFetchingData = createSelector(
    getComponentState,
    (state) => state.fetchingData
);
export const getYear = createSelector(
    getComponentState,
    (state) => state.year
);
export const getMonth = createSelector(
    getComponentState,
    (state) => state.month
);
export const getActiveIndex = createSelector(
    getComponentState,
    (state) => state.activeIndex
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);
export const getDeleting = createSelector(
    getComponentState,
    (state) => state.deleting
);
export const getIdModal = createSelector(
    getComponentState,
    (state) => state.idModal
);
export const getUploading = createSelector(
    getComponentState,
    (state) => state.uploading
);
export const getFile = createSelector(
    getComponentState,
    (state) => state.file
);
export const getUploadType = createSelector(
    getComponentState,
    (state) => state.uploadType
);
export const getUploadYear = createSelector(
    getComponentState,
    (state) => state.uploadYear
);
export const getUploadMonth = createSelector(
    getComponentState,
    (state) => state.uploadMonth
);
export const getUploadDay = createSelector(
    getComponentState,
    (state) => state.uploadDay
);
export const getDownloading = createSelector(
    getComponentState,
    (state) => state.downloading
);
export const getSearchedYear = createSelector(
    getComponentState,
    (state) => state.searchedYear
);
export const getSearchedMonth = createSelector(
    getComponentState,
    (state) => state.searchedMonth
);
export const getUploadOption = createSelector(
    getComponentState,
    (state) => state.uploadOption
);
export const getDownloadType = createSelector(
    getComponentState,
    (state) => state.downloadType
);
export const getOverrideWarning = createSelector(
    getComponentState,
    (state) => state.overrideWarning
);
export const getChart = createSelector(
    getComponentState,
    (state) => state.chart
);
export const getChartType = createSelector(
    getComponentState,
    (state) => state.chartType
);
export const getFetchingChart = createSelector(
    getComponentState,
    (state) => state.fetchingChart
);