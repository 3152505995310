import React, { Fragment } from 'react';
import { Dialog } from './../../common/dialog';
import { useDropzone } from 'react-dropzone';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import moment from 'moment';
import { empty } from './../../../utils';

const UploadModal = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
        accept: '.xlsx',
        onDrop: (files) => {
            props.setFile(files[0]);
        }
    });

    let label = 'Upload';
    if (props.uploading) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleHideUploadModal = () => {
        props.setUploadOption('');
        props.setUploadYear('');
        props.setUploadMonth('');
        props.setUploadDay('');
        props.setDownloadType('');
        props.setFile(null);
        props.hideUploadModal();
    };

    const renderSelectedFile = () => {
        if (props.file == null) {
            return null;
        }

        return <div className="selected-file-container"><b>Selected File:</b> {props.file.name}</div>;
    };

    const handleChangeDate = (date1, date2, options) => {
        props.setUploadYear(date2.getFullYear());
        props.setUploadMonth(date2.getMonth() + 1);
        props.setUploadDay(date2.getDate());

        if (options.selected) {
            props.setOverrideWarning(true);
        } else {
            props.setOverrideWarning(false);
        }
    };

    const handleChangeUploadOption = (value) => {
        props.setUploadOption(value);

        props.setUploadYear('');
        props.setUploadMonth('');
        props.setUploadDay('');
        props.setFile(null);
    };

    const handleChangeDownloadType = (value) => {
        props.setDownloadType(value);
    };

    const filterDates = (date) => {
        return parseInt(date.getDate()) > 11;
    };

    const renderDateSelection = () => {
        if (props.uploadOption === '') {
            return null;
        }

        let overrideWarning = null;
        if (props.overrideWarning) {
            overrideWarning = (
                <div className="override-warning">
                    <div className="icon">
                        <i className="fa-solid fa-triangle-exclamation"></i>
                    </div>
                    <div className="message">
                        You are about to override the data for the selected day!
                    </div>
                </div>
            );
        }

        if (props.uploadOption === 1) {
            let selectedDate = null;
            if (!empty(props.uploadYear)) {
                selectedDate = (
                    <div className="selected-date">
                        Selected date: {moment(props.uploadYear + '-' + props.uploadMonth + '-' + props.uploadDay).format('MMMM Do YYYY')}
                    </div>
                );
            }

            let selectedDates = [];
            for (const record of props.data.records) {
                if (parseInt(record.type) === 1) {
                    selectedDates.push(new Date(record.year + '-' + record.month + '-' + record.day + ' 12:00:00'));
                }
            }

            const bookedStyle = { border: '2px solid currentColor' };
            const bookedDays = [];
            if (!empty(props.uploadYear)) {
                bookedDays.push(new Date(props.uploadYear + '-' + props.uploadMonth + '-' + props.uploadDay + ' 12:00:00'));
            }

            return (
                <div className="fields">
                    <h2>Upload current scorecard month</h2>
                    <div className="calendar-field">
                        <DayPicker
                            mode="single"
                            modifiers={{ booked: bookedDays }}
                            modifiersStyles={{ booked: bookedStyle }}
                            selected={selectedDates}
                            onSelect={handleChangeDate}
                        />
                    </div>
                    {selectedDate}
                    {overrideWarning}
                    <div className="field mt-2">
                        <div className="label">File:</div>
                        <div {...getRootProps({ className: 'dropzone-container' })}>
                            <input {...getInputProps()} />
                            <button>Browse</button>
                        </div>
                        {renderSelectedFile()}
                    </div>
                </div>
            );
        } else if (props.uploadOption === 2) {
            let selectedDate = null;
            if (!empty(props.uploadYear)) {
                const previousMonth = moment(props.uploadYear + '-' + props.uploadMonth + '-01').subtract(1, 'days').format('MMMM YYYY');

                selectedDate = (
                    <Fragment>
                        <div className="after-month">Current month: <span>{previousMonth}</span></div>
                        <div className="selected-date">
                            Selected date: Day {props.uploadDay}
                        </div>
                    </Fragment>
                );
            }

            let selectedDates = [];
            for (const record of props.data.records) {
                if (parseInt(record.type) === 2) {
                    const currentMonth = moment(record.year + '-' + record.month + '-01');
                    currentMonth.add(1, 'month');
                    selectedDates.push(new Date(currentMonth.format('YYYY') + '-' + currentMonth.format('MM') + '-' + record.day.replace('-add', '') + ' 12:00:00'));
                }
            }

            const bookedStyle = { border: '2px solid currentColor' };
            const bookedDays = [];
            if (!empty(props.uploadYear)) {
                bookedDays.push(new Date(props.uploadYear + '-' + props.uploadMonth + '-' + props.uploadDay + ' 12:00:00'));
            }

            return (
                <div className="fields">
                    <h2>Upload previous scorecard month</h2>
                    <div className="calendar-field">
                        <DayPicker
                            mode="single"
                            modifiers={{ booked: bookedDays }}
                            modifiersStyles={{ booked: bookedStyle }}
                            selected={selectedDates}
                            onSelect={handleChangeDate}
                            disabled={filterDates}
                        />
                    </div>
                    {selectedDate}
                    {overrideWarning}
                    <div className="field mt-2">
                        <div className="label">File:</div>
                        <div {...getRootProps({ className: 'dropzone-container' })}>
                            <input {...getInputProps()} />
                            <button>Browse</button>
                        </div>
                        {renderSelectedFile()}
                    </div>
                </div>
            );
        }

        return null;
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={handleHideUploadModal}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={props.uploadExcel} disabled={props.uploading}>{label}</button>
    ];
    return (
        <Dialog
            name="upload-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="admin-upload-dialog"
        >
            <div className="admin-upload-modal-container">
                <h1>Upload Scorecard Report</h1>
                <div className="mb-2"><b>Select type of file:</b></div>
                <div className="options">
                    <div className="option">
                        <input type="radio" checked={props.downloadType === 1} onClick={handleChangeDownloadType.bind(this, 1)} /> Auto Download
                    </div>
                    <div className="option">
                        <input type="radio" checked={props.downloadType === 2} onClick={handleChangeDownloadType.bind(this, 2)} /> Manual Download
                    </div>
                </div>
                <div className="mt-3 mb-2"><b>Make Selection:</b></div>
                <div className="options">
                    <div className="option">
                        <input type="radio" checked={props.uploadOption === 1} onClick={handleChangeUploadOption.bind(this, 1)} /> Current Month
                    </div>
                    <div className="option">
                        <input type="radio" checked={props.uploadOption === 2} onClick={handleChangeUploadOption.bind(this, 2)} /> Previous Month (1-11 days)
                    </div>
                </div>
                {renderDateSelection()}
            </div>
        </Dialog >
    );
});

export default UploadModal;
