import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { empty } from './../../../utils';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const Table = (props) => {
    if (props.fetchingData) {
        return (
            <div className="loading-data">
                <i className="fas fa-circle-notch fa-spin" /> Loading data...
            </div>
        );
    }

    const nameColumnWidth = props.nameColumnWidth;
    const columns = props.columns;
    const section = parseInt(props.scorecardSection);

    let searchedMonth = parseInt(props.searchedMonth);
    if (searchedMonth < 10) {
        searchedMonth = '0' + searchedMonth;
    }

    const days = [];
    let day = moment(props.searchedYear + '-' + searchedMonth + '-01');
    days.push(moment(day));

    let i = 1;
    let currentMonth = parseInt(moment(props.searchedYear + '-' + searchedMonth + '-01').format('MM'));
    while (parseInt(day.format('MM')) === currentMonth && i < 35) {
        day.add(1, 'days');
        days.push(moment(day));
        i++;
    }

    for (let i = 1; i < 11; i++) {
        day.add(1, 'days');
        days.push(moment(day));
    }

    const validateIndex = (index) => {
        if (section === 1) {
            return true;
        }

        if (section === 2 && index >= 73 && index <= 115) {    // Full-Service Verifications
            return true;
        }
        if (section === 3 && index >= 116 && index <= 130) {    // Move/Update Verifications
            return true;
        }
        if (section === 4 && index >= 131 && index <= 137) {    // Entry Point Validations - Info Only
            return true;
        }
        if (section === 5 && index >= 138 && index <= 187) {    // eDoc Nesting/Sortation Validations - Info Only
            return true;
        }
        if (section === 6 && index >= 188 && index <= 197) {    // Seamless Verifications
            return true;
        }
        if (section === 7 && index >= 198 && index <= 202) {    // Automated Verifications
            return true;
        }
        if (section === 8 && index >= 203 && index <= 217) {    // Sampling Verifications
            return true;
        }
        if (section === 9 && index >= 218 && index <= 227) {    // Nesting Sampling Validations - Info Only
            return true;
        }
        if (section === 10 && index >= 228) {    // Mail Irregularities
            return true;
        }

        return false;
    };

    const handleShowChartModal = (row) => {
        props.fetchChart(row);
        props.showChartModal();
    };

    const renderServicesNames = () => {
        if (props.data == null) {
            return null;
        }

        const rowsData = [];
        for (let i = 0; i < props.data.services.length; i++) {
            rowsData.push(props.data.services[i]);
        }

        rowsData.splice(220, 0, 'Mail Irregularities');
        rowsData.splice(211, 0, 'Nesting Sampling Validations - Info Only');
        rowsData.splice(197, 0, 'Sampling Verifications');
        rowsData.splice(193, 0, 'Automated Verifications');
        rowsData.splice(184, 0, 'Seamless Verifications');
        rowsData.splice(135, 0, 'eDoc Nesting/Sortation Validations - Info Only');
        rowsData.splice(129, 0, 'Entry Point Validations - Info Only');
        rowsData.splice(115, 0, 'Move/Update Verifications');
        rowsData.splice(73, 0, 'Full-Service Verifications');

        const rows = [];
        for (let i = 0; i < rowsData.length; i++) {
            if (!validateIndex(i)) {
                continue;
            }

            let className = ['subheader', (rows.length % 2 === 0) ? 'even' : 'odd'];
            if (rows.length === 0) {
                className.push('first');
            }

            if (typeof rowsData[i] === 'object') {
                let chartIcon = null;
                if (![8, 125, 181].includes(parseInt(rowsData[i].row_number))) {
                    chartIcon = (
                        <div className="icons">
                            <Tooltip title="View Chart" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fa-solid fa-chart-line" onClick={handleShowChartModal.bind(this, rowsData[i].row_number)}></i>
                            </Tooltip>
                        </div>
                    );
                }

                rows.push(
                    <div className={className.join(' ')} style={{ width: nameColumnWidth }}>
                        <div className="name">{rowsData[i].name}</div>
                        <div className="icons">
                            {chartIcon}
                        </div>
                    </div>
                );
            } else {
                className.push('title');
                rows.push(<div className={className.join(' ')} style={{ width: nameColumnWidth }}>{rowsData[i]}</div>);
            }
        }

        return rows;
    };

    const renderServicesThresholds = () => {
        if (props.data == null) {
            return null;
        }

        const rowsData = [];
        for (let i = 0; i < props.data.services.length; i++) {
            rowsData.push(props.data.services[i]);
        }

        rowsData.splice(220, 0, 'Mail Irregularities');
        rowsData.splice(211, 0, 'Nesting Sampling Validations - Info Only');
        rowsData.splice(197, 0, 'Sampling Verifications');
        rowsData.splice(193, 0, 'Automated Verifications');
        rowsData.splice(184, 0, 'Seamless Verifications');
        rowsData.splice(135, 0, 'eDoc Nesting/Sortation Validations - Info Only');
        rowsData.splice(129, 0, 'Entry Point Validations - Info Only');
        rowsData.splice(115, 0, 'Move/Update Verifications');
        rowsData.splice(73, 0, 'Full-Service Verifications');

        const rows = [];
        for (let i = 0; i < rowsData.length; i++) {
            if (!validateIndex(i)) {
                continue;
            }

            let className = ['subheader', 'threshold', (rows.length % 2 === 0) ? 'even' : 'odd'];
            if (rows.length === 0) {
                className.push('first');
            }

            if (typeof rowsData[i] === 'object') {
                rows.push(<div className={className.join(' ')}>{rowsData[i].threshold == null ? null : numeral(rowsData[i].threshold).format('0.00%')}</div>);
            } else {
                className.push('threshold');
                rows.push(<div className={className.join(' ')}></div>);
            }
        }

        return rows;
    };

    const renderDayData = (day, dayIndex) => {
        if (props.data == null || !props.data.servicesData || props.data.servicesData.length === 0) {
            return null;
        }

        let month = props.searchedMonth;
        if (month < 10) {
            month = '0' + month;
        }

        const rowsData = [];
        for (let i = 0; i < props.data.services.length; i++) {
            const service = props.data.services[i];

            let className = ['value-cell'];
            let activeService = null;;
            for (let j = 0; j < props.data.servicesData.length; j++) {
                if (parseInt(service.id) === parseInt(props.data.servicesData[j].id)) {
                    activeService = props.data.servicesData[j];
                    break;
                }
            }

            if (activeService == null) {
                rowsData.push(null);
                continue;
            }

            let dataElement = activeService.data[dayIndex];
            if (!dataElement || dataElement == null) {
                rowsData.push(null);
                continue;
            }

            let value = dataElement.value;
            if (!empty(value) && value !== 'N/A') {
                if (parseInt(activeService.value_type) === 1) {     // Integer
                    value = numeral(parseInt(dataElement.value)).format('0,0');
                } else if (parseInt(activeService.value_type) === 2) {     // Percentage
                    value = numeral(parseFloat(dataElement.value)).format('0.00%');
                } else if (parseInt(activeService.value_type) === 4) {     // Money
                    value = numeral(parseFloat(dataElement.value)).format('$0,0.00');
                }
            }

            const thresholdLevel = parseInt(dataElement.threshold_level);
            if (thresholdLevel === 1) {
                className.push('green-threshold');
            } else if (thresholdLevel === 2) {
                className.push('yellow-threshold');
            } else if (thresholdLevel === 3) {
                className.push('red-threshold');
            }

            let arrow = null;
            const previousLevel = parseInt(dataElement.previous_level);
            if (previousLevel === 1) {
                className.push('red-previous');
                arrow = '↑';
            } else if (previousLevel === 2) {
                className.push('green-previous');
                arrow = '↓';
            }

            rowsData.push({
                className,
                arrow: arrow,
                value: value,
            });
        }

        rowsData.splice(220, 0, null);
        rowsData.splice(211, 0, null);
        rowsData.splice(197, 0, null);
        rowsData.splice(193, 0, null);
        rowsData.splice(184, 0, null);
        rowsData.splice(135, 0, null);
        rowsData.splice(129, 0, null);
        rowsData.splice(115, 0, null);
        rowsData.splice(73, 0, null);

        const rows = [];
        for (let i = 0; i < rowsData.length; i++) {
            if (!validateIndex(i)) {
                continue;
            }

            let className = null;
            if (rowsData[i] != null) {
                className = rowsData[i].className;
            } else {
                className = ['value-cell'];
            }

            className.push((rows.length % 2 === 0) ? 'even' : 'odd');
            if (rows.length === 0) {
                className.push('first');
            }

            if (rowsData[i] == null) {
                rows.push(<div className={className.join(' ')}></div>);
            } else {
                rows.push(<div className={className.join(' ')}>{rowsData[i].arrow} {rowsData[i].value}</div>);
            }
        }

        return rows;
    };

    const renderDays = () => {
        return days.map((day, index) => {
            if (index < props.activeIndex || index >= columns + props.activeIndex) {
                return null;
            }

            let className = ['value-column'];
            if (parseInt(day.day()) === 0 || parseInt(day.day()) === 6) {
                className.push('weekend');
            }

            let dayName = day.format('MMM DD');
            if (parseInt(props.searchedMonth) !== parseInt(day.format('MM'))) {
                dayName = 'Day ' + day.format('D');
            }

            return (
                <div className={className.join(' ')}>
                    <div className="header value-cell">{dayName}</div>
                    {renderDayData(day, index)}
                </div>
            );
        });
    };

    const renderDayEmptyData = () => {
        if (props.data == null) {
            return null;
        }

        const rowsData = [];
        for (let i = 0; i < props.data.services.length; i++) {
            rowsData.push(props.data.services[i]);
        }

        rowsData.splice(220, 0, null);
        rowsData.splice(211, 0, null);
        rowsData.splice(197, 0, null);
        rowsData.splice(193, 0, null);
        rowsData.splice(184, 0, null);
        rowsData.splice(135, 0, null);
        rowsData.splice(129, 0, null);
        rowsData.splice(115, 0, null);
        rowsData.splice(73, 0, null);

        const rows = [];
        for (let i = 0; i < rowsData.length; i++) {
            if (!validateIndex(i)) {
                continue;
            }

            let className = ['value-cell', (rows.length % 2 === 0) ? 'even' : 'odd'];
            if (rows.length === 0) {
                className.push('first');
            }

            rows.push(<div className={className.join(' ')}></div>);
        }

        return rows;
    };

    let leftArrowClasses = ['header', 'value-cell', 'arrow'];
    if (parseInt(props.activeIndex) === 0) {
        leftArrowClasses.push('disabled');
    }

    let rightArrowClasses = ['header', 'value-cell', 'arrow', 'right-arrow'];
    if (parseInt(props.activeIndex) + columns === days.length) {
        rightArrowClasses.push('disabled');
    }

    const handleLeftArrow = () => {
        if (parseInt(props.activeIndex) === 0) {
            return;
        }

        props.setActiveIndex(props.activeIndex - 1);
    };

    const handleRightArrow = () => {
        if (parseInt(props.activeIndex) + columns === days.length) {
            return;
        }

        props.setActiveIndex(props.activeIndex + 1);
    };

    return (
        <div className="table-container" style={{ width: nameColumnWidth + 90 + (40 * 2) + columns * 95 + 16 }}>
            <div className="name-column">
                <div className="header" style={{ width: nameColumnWidth }}>Name</div>
                {renderServicesNames()}
            </div>
            <div className="threshold-column">
                <div className="header threshold">Threshold</div>
                {renderServicesThresholds()}
            </div>
            <div className="value-column arrow-column">
                <div className={leftArrowClasses.join(' ')}>
                    <i className="fa-solid fa-caret-left" onClick={handleLeftArrow}></i>
                </div>
                {renderDayEmptyData()}
            </div>
            {renderDays()}
            <div className="value-column arrow-column">
                <div className={rightArrowClasses.join(' ')}>
                    <i className="fa-solid fa-caret-right" onClick={handleRightArrow}></i>
                </div>
                {renderDayEmptyData()}
            </div>
        </div>
    );
}

export default Table;
