import Immutable from 'immutable';
import * as types from './Scorecard.types';

const initialState = {
    data: null,
    fetchingData: false,
    deleting: false,
    idModal: null,
    year: '',
    month: '',
    activeIndex: 0,
    file: null,
    uploading: false,
    uploadType: '1',
    uploadYear: '',
    uploadMonth: '',
    uploadDay: '',
    searchedYear: '',
    searchedMonth: '',
    downloading: false,
    uploadOption: '',
    downloadType: '',
    overrideWarning: false,
    history: null,
    chart: null,
    fetchingChart: false,
    chartType: 'line',
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_DATA:
            return state.setIn(['fetchingData'], true).toJS();

        case types.FETCH_DATA_SUCCESS:
            return state.setIn(['fetchingData'], false)
                .setIn(['data'], action.payload)
                .toJS();

        case types.FETCH_DATA_ERROR:
            return state.setIn(['fetchingData'], false).toJS();

        case types.DELETE_ITEM:
            return state.setIn(['deleting'], true).toJS();

        case types.DELETE_ITEM_SUCCESS:
            return state.setIn(['deleting'], false)
                .setIn(['idModal'], null)
                .toJS();

        case types.DELETE_ITEM_ERROR:
            return state.setIn(['deleting'], false).toJS();

        case types.UPLOAD_EXCEL:
            return state.setIn(['uploading'], true).toJS();

        case types.UPLOAD_EXCEL_SUCCESS:
            return state.setIn(['uploading'], false)
                .setIn(['file'], null)
                .setIn(['uploadYear'], '')
                .setIn(['uploadMonth'], '')
                .setIn(['uploadDay'], '')
                .setIn(['uploadOption'], '')
                .setIn(['downloadType'], '')
                .setIn(['overrideWarning'], '')
                .toJS();

        case types.UPLOAD_EXCEL_ERROR:
            return state.setIn(['uploading'], false).toJS();

        case types.DOWNLOAD_EXCEL:
            return state.setIn(['downloading'], true).toJS();

        case types.DOWNLOAD_EXCEL_SUCCESS:
            return state.setIn(['downloading'], false).toJS();

        case types.DOWNLOAD_EXCEL_ERROR:
            return state.setIn(['downloading'], false).toJS();

        case types.FETCH_CHART:
            return state.setIn(['fetchingChart'], true).toJS();

        case types.FETCH_CHART_SUCCESS:
            return state.setIn(['fetchingChart'], false)
                .setIn(['chart'], action.payload)
                .toJS();

        case types.FETCH_CHART_ERROR:
            return state.setIn(['fetchingChart'], false).toJS();

        case types.SET_YEAR:
            return state.setIn(['year'], action.payload).toJS();

        case types.SET_MONTH:
            return state.setIn(['month'], action.payload).toJS();

        case types.SET_SEARCHED_YEAR:
            return state.setIn(['searchedYear'], action.payload).toJS();

        case types.SET_SEARCHED_MONTH:
            return state.setIn(['searchedMonth'], action.payload).toJS();

        case types.SET_ACTIVE_INDEX:
            return state.setIn(['activeIndex'], action.payload).toJS();

        case types.SET_FILE:
            return state.setIn(['file'], action.payload).toJS();

        case types.SET_UPLOAD_TYPE:
            return state.setIn(['uploadType'], action.payload).toJS();

        case types.SET_UPLOAD_YEAR:
            return state.setIn(['uploadYear'], action.payload).toJS();

        case types.SET_UPLOAD_MONTH:
            return state.setIn(['uploadMonth'], action.payload).toJS();

        case types.SET_UPLOAD_DAY:
            return state.setIn(['uploadDay'], action.payload).toJS();

        case types.SET_UPLOAD_OPTION:
            return state.setIn(['uploadOption'], action.payload).toJS();

        case types.SET_DOWNLOAD_TYPE:
            return state.setIn(['downloadType'], action.payload).toJS();

        case types.SET_OVERRIDE_WARNING:
            return state.setIn(['overrideWarning'], action.payload).toJS();

        case types.SET_CHART:
            return state.setIn(['chart'], action.payload).toJS();

        case types.SET_CHART_TYPE:
            return state.setIn(['chartType'], action.payload).toJS();

        case types.SET_IS_DEFAULT_CHART:
            return state.setIn(['chart', 'isDefaultChart'], action.payload.value).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.SET_ID_MODAL:
            return state.setIn(['idModal'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}