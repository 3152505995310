import React from 'react';
import numeral from 'numeral';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { empty } from './../../../utils';

const ScorecardPieChart = (props) => {
    const tooltipFormatter = (format, element) => {
        return 'Count: ' + numeral(element).format('0,0');
    };

    let numberFormat = '0,0';
    if (props.numberFormat && props.numberFormat != null) {
        numberFormat = props.numberFormat;
    }

    let data = [];
    if (props.data) {
        data = props.data;
    }

    let colors = [];
    if (props.colors) {
        colors = props.colors;
    }

    let hasData = false;
    for (const element of data) {
        if (element.value > 0) {
            hasData = true;
            break;
        }
    }

    const renderLabel = (data) => {
        let value = 0;
        if (data.count && !empty(data.count) && data.count >= 0.01) {
            value = data.count;
        }

        return data.name + ' (' + numeral(value).format('0,0.[00]%') + ')';
    };

    let chartContainer = <div className="no-data">No data.</div>;
    if (hasData) {
        chartContainer = (
            <ResponsiveContainer>
                <PieChart>
                    <Tooltip formatter={tooltipFormatter.bind(this, numberFormat)} />
                    <Pie
                        data={data}
                        outerRadius={130}
                        fill="#8884d8"
                        dataKey="value"
                        label={renderLabel}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        );
    }

    return (
        <div className="chart-container">
            <h2>{props.chartTitle}</h2>
            <div className="chart" style={{ width: '100%', height: 400 }}>
                {chartContainer}
            </div>
        </div>
    );
}

export default ScorecardPieChart;
