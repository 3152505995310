export const FETCH_DATA = 'SCORECARD/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'SCORECARD/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'SCORECARD/FETCH_DATA_ERROR';
export const DELETE_ITEM = 'SCORECARD/DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'SCORECARD/DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_ERROR = 'SCORECARD/DELETE_ITEM_ERROR';
export const UPLOAD_EXCEL = 'SCORECARD/UPLOAD_EXCEL';
export const UPLOAD_EXCEL_SUCCESS = 'SCORECARD/UPLOAD_EXCEL_SUCCESS';
export const UPLOAD_EXCEL_ERROR = 'SCORECARD/UPLOAD_EXCEL_ERROR';
export const DOWNLOAD_EXCEL = 'SCORECARD/DOWNLOAD_EXCEL';
export const DOWNLOAD_EXCEL_SUCCESS = 'SCORECARD/DOWNLOAD_EXCEL_SUCCESS';
export const DOWNLOAD_EXCEL_ERROR = 'SCORECARD/DOWNLOAD_EXCEL_ERROR';
export const FETCH_CHART = 'SCORECARD/FETCH_CHART';
export const FETCH_CHART_SUCCESS = 'SCORECARD/FETCH_CHART_SUCCESS';
export const FETCH_CHART_ERROR = 'SCORECARD/FETCH_CHART_ERROR';
export const SET_YEAR = 'SCORECARD/SET_YEAR';
export const SET_MONTH = 'SCORECARD/SET_MONTH';
export const SET_SEARCHED_YEAR = 'SCORECARD/SET_SEARCHED_YEAR';
export const SET_SEARCHED_MONTH = 'SCORECARD/SET_SEARCHED_MONTH';
export const SET_ACTIVE_INDEX = 'SCORECARD/SET_ACTIVE_INDEX';
export const SET_FILE = 'SCORECARD/SET_FILE';
export const SET_UPLOAD_TYPE = 'SCORECARD/SET_UPLOAD_TYPE';
export const SET_UPLOAD_YEAR = 'SCORECARD/SET_UPLOAD_YEAR';
export const SET_UPLOAD_MONTH = 'SCORECARD/SET_UPLOAD_MONTH';
export const SET_UPLOAD_DAY = 'SCORECARD/SET_UPLOAD_DAY';
export const SET_UPLOAD_OPTION = 'SCORECARD/SET_UPLOAD_OPTION';
export const SET_DOWNLOAD_TYPE = 'SCORECARD/SET_DOWNLOAD_TYPE';
export const SET_OVERRIDE_WARNING = 'SCORECARD/SET_OVERRIDE_WARNING';
export const SET_CHART = 'SCORECARD/SET_CHART';
export const SET_CHART_TYPE = 'SCORECARD/SET_CHART_TYPE';
export const SET_IS_DEFAULT_CHART = 'SCORECARD/SET_IS_DEFAULT_CHART';
export const SET_HISTORY = 'SCORECARD/SET_HISTORY';
export const SET_ID_MODAL = 'SCORECARD/SET_ID_MODAL';
export const RESET_STATE = 'SCORECARD/RESET_STATE';