import axios from 'axios';
import * as types from './Dashboard.types';
import * as selectors from './Dashboard.selectors';
import { adminValidateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchData = () => (dispatch, getState) => {
    const year = selectors.getYear(getState());
    const month = selectors.getMonth(getState());

    dispatch(setSearchedYear(year));
    dispatch(setSearchedMonth(month));

    dispatch({ type: types.FETCH_DATA, payload: null });
    axios.get(apiUrl + '/record/charts-data?year=' + year + '&month=' + month,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_DATA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_DATA_ERROR));
        });
}

export const setYear = (value) => (dispatch) => {
    dispatch({
        type: types.SET_YEAR,
        payload: value,
    });
}

export const setMonth = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MONTH,
        payload: value,
    });
}

export const setSearchedYear = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCHED_YEAR,
        payload: value,
    });
}

export const setSearchedMonth = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCHED_MONTH,
        payload: value,
    });
}

export const setHistory = (value) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: value,
    });
}

export const setRow = (value) => (dispatch) => {
    dispatch({
        type: types.SET_ROW,
        payload: value,
    });
}

export const setChartType = (index, value) => (dispatch) => {
    dispatch({
        type: types.SET_CHART_TYPE,
        payload: { index, value },
    });
}

export const setIsDefaultChart = (index, value) => (dispatch) => {
    dispatch({
        type: types.SET_IS_DEFAULT_CHART,
        payload: { index, value },
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}