import axios from 'axios';
import * as types from './Scorecard.types';
import * as selectors from './Scorecard.selectors';
import { adminValidateAuthInResponse, showNotification } from './../../utils';
import * as dialog from './../common/dialog';
import fileDownload from 'js-file-download';
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchData = () => (dispatch, getState) => {
    const year = selectors.getYear(getState());
    const month = selectors.getMonth(getState());

    dispatch(setSearchedYear(year));
    dispatch(setSearchedMonth(month));

    dispatch({ type: types.FETCH_DATA, payload: null });
    axios.get(apiUrl + '/record/admin-index?year=' + year + '&month=' + month,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_DATA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_DATA_ERROR));
        });
}

export const fetchChart = (row) => (dispatch, getState) => {
    const year = selectors.getYear(getState());
    const month = selectors.getMonth(getState());

    dispatch({ type: types.FETCH_CHART, payload: null });
    axios.get(apiUrl + '/record/chart?year=' + year + '&month=' + month + '&row=' + row,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_CHART_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.FETCH_CHART_ERROR));
        });
}

export const deleteItem = () => (dispatch, getState) => {
    const idModal = selectors.getIdModal(getState());

    dispatch({ type: types.DELETE_ITEM, payload: null });
    axios.delete(apiUrl + '/records/' + idModal,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.DELETE_ITEM_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('delete-modal'));
            showNotification('Item Deleted', 'The item has been deleted successfully', 'success');
            dispatch(fetchData());
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.DELETE_ITEM_ERROR));
        });
}

export const uploadExcel = () => (dispatch, getState) => {
    const file = selectors.getFile(getState());
    const year = selectors.getUploadYear(getState());
    const month = selectors.getUploadMonth(getState());
    const day = selectors.getUploadDay(getState());
    const type = selectors.getUploadOption(getState());
    const downloadType = selectors.getDownloadType(getState());

    if (downloadType === '') {
        return showNotification('Select Download Type', 'Select the Type of file', 'info');
    }
    if (type === '') {
        return showNotification('Select Type', 'Select the Upload Type', 'info');
    }
    if (year === '') {
        return showNotification('Select Date', 'Select the Date for the file', 'info');
    }
    if (month === '') {
        return showNotification('Select Date', 'Select the Date for the file', 'info');
    }
    if (day === '') {
        return showNotification('Select Date', 'Select the Date for the file', 'info');
    }
    if (file == null) {
        return showNotification('No File selected', 'Select an Excel file to upload', 'info');
    }

    var formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('download-type', downloadType);

    if (parseInt(type) === 1) {
        formData.append('year', year);
        formData.append('month', month);
        formData.append('day', day);
    } else if (parseInt(type) === 2) {
        const date = moment(year + '-' + month + '-' + day).subtract(12, 'days');

        formData.append('year', date.format('YYYY'));
        formData.append('month', parseInt(date.format('MM')));
        formData.append('day', day + '-add');
    }

    dispatch({ type: types.UPLOAD_EXCEL, payload: null });
    axios.post(apiUrl + '/record/upload-excel', formData,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.UPLOAD_EXCEL_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('upload-modal'));
            showNotification('File uploaded', 'The file has been uploaded successfully', 'success');
            dispatch(fetchData());
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.UPLOAD_EXCEL_ERROR));
        });
}

export const downloadExcel = () => (dispatch, getState) => {
    const year = selectors.getYear(getState());
    const month = selectors.getMonth(getState());

    dispatch({ type: types.DOWNLOAD_EXCEL, payload: null });
    axios.get(apiUrl + '/record/export-excel?year=' + year + '&month=' + month,
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.DOWNLOAD_EXCEL_SUCCESS, payload: null });
            fileDownload(response.data, 'Scorecard ' + year + ' - ' + month + '.xlsx');
        })
        .catch((error) => {
            dispatch(adminValidateAuthInResponse(error, types.DOWNLOAD_EXCEL_ERROR));
        });
}

export const setYear = (value) => (dispatch) => {
    dispatch({
        type: types.SET_YEAR,
        payload: value,
    });
}

export const setMonth = (value) => (dispatch) => {
    dispatch({
        type: types.SET_MONTH,
        payload: value,
    });
}

export const setSearchedYear = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCHED_YEAR,
        payload: value,
    });
}

export const setSearchedMonth = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCHED_MONTH,
        payload: value,
    });
}

export const setActiveIndex = (value) => (dispatch) => {
    dispatch({
        type: types.SET_ACTIVE_INDEX,
        payload: value,
    });
}

export const setFile = (file) => (dispatch) => {
    dispatch({
        type: types.SET_FILE,
        payload: file,
    });
}

export const setUploadType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_UPLOAD_TYPE,
        payload: value,
    });
}

export const setUploadYear = (value) => (dispatch) => {
    dispatch({
        type: types.SET_UPLOAD_YEAR,
        payload: value,
    });
}

export const setUploadMonth = (value) => (dispatch) => {
    dispatch({
        type: types.SET_UPLOAD_MONTH,
        payload: value,
    });
}

export const setUploadDay = (value) => (dispatch) => {
    dispatch({
        type: types.SET_UPLOAD_DAY,
        payload: value,
    });
}

export const setUploadOption = (value) => (dispatch) => {
    dispatch({
        type: types.SET_UPLOAD_OPTION,
        payload: parseInt(value),
    });
}

export const setDownloadType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_DOWNLOAD_TYPE,
        payload: parseInt(value),
    });
}

export const setOverrideWarning = (value) => (dispatch) => {
    dispatch({
        type: types.SET_OVERRIDE_WARNING,
        payload: value,
    });
}

export const setChart = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CHART,
        payload: value,
    });
}

export const setChartType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CHART_TYPE,
        payload: value,
    });
}

export const setHistory = (value) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY,
        payload: value,
    });
}

export const setIdModal = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_MODAL,
        payload: id,
    });
}

export const setIsDefaultChart = (rowNumber, value) => (dispatch) => {
    dispatch({
        type: types.SET_IS_DEFAULT_CHART,
        payload: { rowNumber, value },
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}