import React, { Fragment, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Scorecard.scss';
import Table from './Table';
import DeleteModal from './DeleteModal';
import UploadModal from './UploadModal';
import ChartModal from './ChartModal';
import AdminLayout from './../AdminLayout';
import * as actions from './Scorecard.actions';
import * as selectors from './Scorecard.selectors';
import * as layoutActions from './../AdminLayout/AdminLayout.actions';
import * as layoutSelectors from './../AdminLayout/AdminLayout.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import { Helmet } from 'react-helmet';
import * as dialog from './../common/dialog';
import moment from 'moment';
import { stringifyEqualityCheck } from './../../utils';
import { Redirect } from 'react-router-dom';

const Scorecard = (props) => {
    /* State to props */
    const fetchingData = useSelector(selectors.getFetchingData);
    const deleting = useSelector(selectors.getDeleting);
    const idModal = useSelector(selectors.getIdModal);
    const year = useSelector(selectors.getYear);
    const month = useSelector(selectors.getMonth);
    const activeIndex = useSelector(selectors.getActiveIndex);
    const data = useSelector(selectors.getData, stringifyEqualityCheck);
    const uploading = useSelector(selectors.getUploading);
    const file = useSelector(selectors.getFile, stringifyEqualityCheck);
    const uploadType = useSelector(selectors.getUploadType);
    const uploadYear = useSelector(selectors.getUploadYear);
    const uploadMonth = useSelector(selectors.getUploadMonth);
    const uploadDay = useSelector(selectors.getUploadDay);
    const downloading = useSelector(selectors.getDownloading);
    const searchedYear = useSelector(selectors.getSearchedYear);
    const searchedMonth = useSelector(selectors.getSearchedMonth);
    const uploadOption = useSelector(selectors.getUploadOption);
    const downloadType = useSelector(selectors.getDownloadType);
    const overrideWarning = useSelector(selectors.getOverrideWarning);
    const chart = useSelector(selectors.getChart, stringifyEqualityCheck);
    const fetchingChart = useSelector(selectors.getFetchingChart);
    const chartType = useSelector(selectors.getChartType);
    const scorecardSection = useSelector(layoutSelectors.getScorecardSection);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchData = useCallback(() => dispatch(actions.fetchData()), [dispatch]);
    const setYear = useCallback((value) => dispatch(actions.setYear(value)), [dispatch]);
    const setMonth = useCallback((value) => dispatch(actions.setMonth(value)), [dispatch]);
    const setHistory = useCallback((value) => dispatch(actions.setHistory(value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const setActiveIndex = useCallback((value) => dispatch(actions.setActiveIndex(value)), [dispatch]);
    const deleteItem = useCallback(() => dispatch(actions.deleteItem()), [dispatch]);
    const uploadExcel = useCallback(() => dispatch(actions.uploadExcel()), [dispatch]);
    const setFile = useCallback((file) => dispatch(actions.setFile(file)), [dispatch]);
    const setUploadType = useCallback((value) => dispatch(actions.setUploadType(value)), [dispatch]);
    const setUploadYear = useCallback((value) => dispatch(actions.setUploadYear(value)), [dispatch]);
    const setUploadMonth = useCallback((value) => dispatch(actions.setUploadMonth(value)), [dispatch]);
    const setUploadDay = useCallback((value) => dispatch(actions.setUploadDay(value)), [dispatch]);
    const downloadExcel = useCallback(() => dispatch(actions.downloadExcel()), [dispatch]);
    const setSearchedYear = useCallback((value) => dispatch(actions.setSearchedYear(value)), [dispatch]);
    const setSearchedMonth = useCallback((value) => dispatch(actions.setSearchedMonth(value)), [dispatch]);
    const setUploadOption = useCallback((value) => dispatch(actions.setUploadOption(value)), [dispatch]);
    const setDownloadType = useCallback((value) => dispatch(actions.setDownloadType(value)), [dispatch]);
    const setOverrideWarning = useCallback((value) => dispatch(actions.setOverrideWarning(value)), [dispatch]);
    const setChart = useCallback((value) => dispatch(actions.setChart(value)), [dispatch]);
    const fetchChart = useCallback((row) => dispatch(actions.fetchChart(row)), [dispatch]);
    const setChartType = useCallback((value) => dispatch(actions.setChartType(value)), [dispatch]);
    const setIsDefaultChart = useCallback((rowNumber, value) => dispatch(actions.setIsDefaultChart(rowNumber, value)), [dispatch]);
    const setDefaultChart = useCallback((rowNumber, value) => dispatch(layoutActions.setDefaultChart(rowNumber, value)), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showUploadModal = useCallback(() => dispatch(dialog.actions.showDialog('upload-modal')), [dispatch]);
    const hideUploadModal = useCallback(() => dispatch(dialog.actions.hideDialog('upload-modal')), [dispatch]);
    const showChartModal = useCallback(() => dispatch(dialog.actions.showDialog('chart-modal')), [dispatch]);
    const hideChartModal = useCallback(() => dispatch(dialog.actions.hideDialog('chart-modal')), [dispatch]);

    let nameColumnWidth = 410;

    let space = window.innerWidth - 260 - nameColumnWidth - 90 - 40 * 2;
    if (space % 95 !== 0) {
        nameColumnWidth += space % 95;
        space = window.innerWidth - 260 - nameColumnWidth - 90 - 40 * 2;
    }

    let columns = Math.floor(space / 95);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        setHistory(props.history);

        setYear(moment().format('YYYY'));
        setMonth(parseInt(moment().format('MM')));

        setSearchedYear(moment().format('YYYY'));
        setSearchedMonth(parseInt(moment().format('MM')));

        let day = moment().format('DD');
        if (day < columns) {
            setActiveIndex(0);
        } else {
            setActiveIndex(day - columns);
        }

        fetchData();

        return () => {
            resetState();
        };
    }, [fetchData, setYear, setMonth, setSearchedYear, setSearchedMonth, setActiveIndex, resetState, setHistory, columns, props.history]);

    if (user == null) {
        return <Redirect to="/login" />; 
    }

    const handleChangeYear = (event) => {
        setYear(event.target.value);
    };

    const handleChangeMonth = (event) => {
        setMonth(event.target.value);
    };

    const renderYears = () => {
        const options = [];
        for (let i = 2022; i <= 2040; i++) {
            options.push(<option value={i}>{i}</option>);
        }
        return options;
    };

    let titleContainer = null;
    if (parseInt(user.type) === 1 || parseInt(user.type) === 2) {   // Super Admin, Admin
        titleContainer = (
            <div className="header-actions-container">
                <div className="search-field">
                    <select value={year} onChange={handleChangeYear}>
                        <option value="">Select Year</option>
                        {renderYears()}
                    </select>
                    <select value={month} onChange={handleChangeMonth}>
                        <option value="">Select Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    <button className="search-button admin-button" onClick={fetchData} disabled={fetchingData}>
                        {fetchingData ? <i className="fas fa-circle-notch fa-spin" /> : 'Search'}
                    </button>
                </div>
                <div className="upload-button-container">
                    <button className="admin-button" onClick={showUploadModal}>
                        <Fragment><i className="fa-solid fa-cloud-arrow-up"></i> Upload Excel</Fragment>
                    </button>
                </div>
                <div className="download-button-container">
                    <button className="admin-button" disabled={downloading} onClick={downloadExcel}>
                        {downloading ? <i className="fas fa-circle-notch fa-spin" /> : <Fragment><i className="fa-solid fa-file-excel"></i> Download Excel</Fragment>}
                    </button>
                </div>
            </div>
        );
    } else if (parseInt(user.type) === 3) {    // Viewer
        titleContainer = (
            <div className="header-actions-container">
                <div className="search-field">
                    <select value={year} onChange={handleChangeYear}>
                        <option value="">Select Year</option>
                        {renderYears()}
                    </select>
                    <select value={month} onChange={handleChangeMonth}>
                        <option value="">Select Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    <button className="search-button admin-button" onClick={fetchData} disabled={fetchingData}>
                        {fetchingData ? <i className="fas fa-circle-notch fa-spin" /> : 'Search'}
                    </button>
                </div>
            </div>
        );
    } else if (parseInt(user.type) === 4) {    // Admin Viewer
        titleContainer = (
            <div className="header-actions-container">
                <div className="search-field">
                    <select value={year} onChange={handleChangeYear}>
                        <option value="">Select Year</option>
                        {renderYears()}
                    </select>
                    <select value={month} onChange={handleChangeMonth}>
                        <option value="">Select Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    <button className="search-button admin-button" onClick={fetchData} disabled={fetchingData}>
                        {fetchingData ? <i className="fas fa-circle-notch fa-spin" /> : 'Search'}
                    </button>
                </div>
                <div className="download-button-container">
                    <button className="admin-button" disabled={downloading} onClick={downloadExcel}>
                        {downloading ? <i className="fas fa-circle-notch fa-spin" /> : <Fragment><i className="fa-solid fa-file-excel"></i> Download Excel</Fragment>}
                    </button>
                </div>
            </div>
        );
    } else if (parseInt(user.type) === 5) {    // Super Viewer
        titleContainer = (
            <div className="header-actions-container">
                <div className="search-field">
                    <select value={year} onChange={handleChangeYear}>
                        <option value="">Select Year</option>
                        {renderYears()}
                    </select>
                    <select value={month} onChange={handleChangeMonth}>
                        <option value="">Select Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    <button className="search-button admin-button" onClick={fetchData} disabled={fetchingData}>
                        {fetchingData ? <i className="fas fa-circle-notch fa-spin" /> : 'Search'}
                    </button>
                </div>
                <div className="upload-button-container">
                    <button className="admin-button" onClick={showUploadModal}>
                        <Fragment><i className="fa-solid fa-cloud-arrow-up"></i> Upload Excel</Fragment>
                    </button>
                </div>
                <div className="download-button-container">
                    <button className="admin-button" disabled={downloading} onClick={downloadExcel}>
                        {downloading ? <i className="fas fa-circle-notch fa-spin" /> : <Fragment><i className="fa-solid fa-file-excel"></i> Download Excel</Fragment>}
                    </button>
                </div>
            </div>
        );
    }

    return (
        <AdminLayout history={props.history}>
            <Helmet>
                <title>Scorecard</title>
            </Helmet>
            <div className="scorecard-container">
                <h1>Scorecard</h1>
                {titleContainer}
                <Table
                    data={data}
                    fetchingData={fetchingData}
                    activeIndex={activeIndex}
                    year={year}
                    month={month}
                    searchedYear={searchedYear}
                    searchedMonth={searchedMonth}
                    scorecardSection={scorecardSection}
                    user={user}
                    nameColumnWidth={nameColumnWidth}
                    columns={columns}
                    showDeleteModal={showDeleteModal}
                    setIdModal={setIdModal}
                    setActiveIndex={setActiveIndex}
                    fetchChart={fetchChart}
                    showChartModal={showChartModal}
                />
            </div>
            <DeleteModal
                idModal={idModal}
                deleting={deleting}
                deleteItem={deleteItem}
                hideDeleteModal={hideDeleteModal}
                setIdModal={setIdModal}
            />
            <UploadModal
                data={data}
                uploading={uploading}
                file={file}
                uploadType={uploadType}
                uploadYear={uploadYear}
                uploadMonth={uploadMonth}
                uploadDay={uploadDay}
                uploadOption={uploadOption}
                downloadType={downloadType}
                overrideWarning={overrideWarning}
                uploadExcel={uploadExcel}
                setFile={setFile}
                hideUploadModal={hideUploadModal}
                setUploadType={setUploadType}
                setUploadYear={setUploadYear}
                setUploadMonth={setUploadMonth}
                setUploadDay={setUploadDay}
                setUploadOption={setUploadOption}
                setDownloadType={setDownloadType}
                setOverrideWarning={setOverrideWarning}
            />
            <ChartModal
                chart={chart}
                fetchingChart={fetchingChart}
                chartType={chartType}
                user={user}
                setChart={setChart}
                hideChartModal={hideChartModal}
                setChartType={setChartType}
                setDefaultChart={setDefaultChart}
                setIsDefaultChart={setIsDefaultChart}
            />
        </AdminLayout>
    );
};

export default Scorecard;
