import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = (props) => {
    const path = props.history.location.pathname;

    const handleClick = (event) => {
        event.preventDefault();
        props.showTermOfUseModal();
    };

    let usersMenu = null;
    if (parseInt(props.user.type) === 1) {  // Super Admin
        usersMenu = (
            <div className={path.includes('/users') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/users">
                    <i className="fa-solid fa-users"></i> Users
                </Link>
            </div>
        );
    }

    const getScorecardClassname = (index) => {
        if (index === parseInt(props.scorecardSection)) {
            return 'section active';
        }

        return 'section';
    };

    const renderScorecardSections = () => {
        if (path === '/') {
            return (
                <div className="scorecard-sections">
                    <div className={getScorecardClassname(1)} onClick={props.setScorecardSection.bind(this, 1)}>Complete View</div>
                    <div className={getScorecardClassname(2)} onClick={props.setScorecardSection.bind(this, 2)}>Full-Service Verifications</div>
                    <div className={getScorecardClassname(3)} onClick={props.setScorecardSection.bind(this, 3)}>Move/Update Verifications</div>
                    <div className={getScorecardClassname(4)} onClick={props.setScorecardSection.bind(this, 4)}>Entry Point Validations - Info Only</div>
                    <div className={getScorecardClassname(5)} onClick={props.setScorecardSection.bind(this, 5)}>eDoc Nesting/Sortation Validations - Info Only</div>
                    <div className={getScorecardClassname(6)} onClick={props.setScorecardSection.bind(this, 6)}>Seamless Verifications</div>
                    <div className={getScorecardClassname(7)} onClick={props.setScorecardSection.bind(this, 7)}>Automated Verifications</div>
                    <div className={getScorecardClassname(8)} onClick={props.setScorecardSection.bind(this, 8)}>Sampling Verifications</div>
                    <div className={getScorecardClassname(9)} onClick={props.setScorecardSection.bind(this, 9)}>Nesting Sampling Validations - Info Only</div>
                    <div className={getScorecardClassname(10)} onClick={props.setScorecardSection.bind(this, 10)}>Mail Irregularities</div>
                </div>
            );
        } else if (path.includes('/scorecard')) {
            return (
                <div className="scorecard-sections">
                    <div className={getScorecardClassname(1)} onClick={props.setScorecardSection.bind(this, 1)}>Complete View</div>
                    <div className={getScorecardClassname(2)} onClick={props.setScorecardSection.bind(this, 2)}>Full-Service Verifications</div>
                    <div className={getScorecardClassname(3)} onClick={props.setScorecardSection.bind(this, 3)}>Move/Update Verifications</div>
                    <div className={getScorecardClassname(4)} onClick={props.setScorecardSection.bind(this, 4)}>Entry Point Validations - Info Only</div>
                    <div className={getScorecardClassname(5)} onClick={props.setScorecardSection.bind(this, 5)}>eDoc Nesting/Sortation Validations - Info Only</div>
                    <div className={getScorecardClassname(6)} onClick={props.setScorecardSection.bind(this, 6)}>Seamless Verifications</div>
                    <div className={getScorecardClassname(7)} onClick={props.setScorecardSection.bind(this, 7)}>Automated Verifications</div>
                    <div className={getScorecardClassname(8)} onClick={props.setScorecardSection.bind(this, 8)}>Sampling Verifications</div>
                    <div className={getScorecardClassname(9)} onClick={props.setScorecardSection.bind(this, 9)}>Nesting Sampling Validations - Info Only</div>
                    <div className={getScorecardClassname(10)} onClick={props.setScorecardSection.bind(this, 10)}>Mail Irregularities</div>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="sidebar-container">
            <div className={path === '/' ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/">
                    <i className="fa-solid fa-chart-pie"></i> Dashboard
                </Link>
            </div>
            <div className={path.includes('/scorecard') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/scorecard">
                    <i className="fa-solid fa-table-list"></i> Scorecard
                </Link>
            </div>
            {usersMenu}
            {renderScorecardSections()}
            <div className="term-of-use">
                <a href="/" onClick={handleClick}>Term of Use</a>
            </div>
        </div>
    );
}

export default Sidebar;
